import { graphql, useStaticQuery } from 'gatsby'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import PostCard, { PostCardProps } from '@/components/shared/Post/PostCard'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

function Post(post: PostCardProps) {
  return (
    <PostCard
      {...post}
      className="flex flex-col flex-shrink-0 space-y-4 desktop:flex-1"
    >
      <>
        <PostCard.Image />
        <PostCard.Title heading="heading-5" />
        <PostCard.Excerpt limitText={70} />
      </>
    </PostCard>
  )
}

const HotSection = () => {
  const { isTablet } = useBreakpoints()
  // use filter categories which have parentDatabaseId = 515 (podcast)
  const data = useStaticQuery(graphql`
    query {
      allWpPost(
        filter: {
          categories: {
            nodes: { elemMatch: { parentDatabaseId: { ne: 515 } } }
          }
        }
        sort: { fields: date, order: DESC }
        limit: 4
      ) {
        edges {
          node {
            title
            slug
            excerpt
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    desktop: gatsbyImageData(aspectRatio: 1)
                    tablet: gatsbyImageData(aspectRatio: 1.33)
                    mobile: gatsbyImageData(aspectRatio: 1)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const posts = data.allWpPost.edges
  const imageType = isTablet ? 'tablet' : 'desktop'
  return (
    <div className="pb-8 desktop:pb-24 base-container">
      <h1 className="section-heading">Bài viết hay</h1>
      {/* Tablet and desktop */}
      <div className="hidden grid-cols-2 gap-5 space-x-4 desktop:flex desktop:gap-0 tablet:grid">
        {posts.map(({ node: post }: any, index: number) => (
          <Post
            key={index}
            title={post.title}
            excerpt={post.excerpt}
            image={post.featuredImage.node.localFile.childImageSharp[imageType]}
            slug={post.slug}
            link={`/${post.slug}`}
          />
        ))}
      </div>
      {/* Mobile */}
      <div className="block tablet:hidden">
        <Swiper
          spaceBetween={16}
          slidesPerView={1.2}
          pagination={true}
          modules={[Pagination]}
          style={{ paddingBottom: 64 }}
        >
          {posts.map(({ node: post }: any, index: number) => (
            <SwiperSlide key={index}>
              <Post
                title={post.title}
                excerpt={post.excerpt}
                image={post.featuredImage.node.localFile.childImageSharp.mobile}
                slug={post.slug}
                link={`/${post.slug}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default HotSection
